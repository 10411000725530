












































































































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import Organization from '../../../../../store/models/Organization'
import CreditLineDeadline from '../../../../../store/models/CreditLineDeadline'
import Dispatching from '../../../../../store/models/Dispatching'
import AccountMixin from '../../../../../mixins/accountMixin'
import { mapGetters } from 'vuex'

interface ComponentData {
  loading: string
  mineColumns: Array<any>
  fnmColumns: Array<any>
  activeTab: string
}
export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin> & ComponentData
>).extend({
  components: {
    BalanceCard
  },

  mixins: [AccountMixin],

  data(): ComponentData {
    return {
      loading: 'none',
      mineColumns: [
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name',
          searchable: true
        },
        {
          label: 'Montant Perçu',
          field: 'totalReceived',
          currency: true
        },
        {
          label: 'Montant Escompté',
          field: 'totalDue',
          currency: true
        },
        {
          label: 'Progression',
          field: 'reimbursementProgress'
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'left'
        }
      ],

      fnmColumns: [
        /* {
          label: 'N° transaction',
          field: 'refundTransaction.reference',
          searchable: true
        }, */
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name',
          searchable: true
        },
        {
          label: 'Montant Remboursé',
          field: 'monthlyAmount',
          currency: true
        },
        {
          label: 'Opérateur mobile',
          field: 'otm'
        },
        {
          label: 'Date/heure',
          field: 'paidAt',
          date: true
        }
      ],

      activeTab: 'beneficiary'
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    columns(this: any) {
      return this.activeTab === 'beneficiary'
        ? this.mineColumns
        : this.fnmColumns
    },

    tableItems(this: any) {
      return this.activeTab === 'beneficiary'
        ? this.dispatchings
        : this.creditLineDeadlines
    },

    isLoading(): boolean {
      return this.loading === this.activeTab
    },

    organization(): Organization {
      return Organization.loggedIn()
    },

    dispatchings() {
      return Dispatching.query()
        .withAll()
        .where('state', 'completed')
        .where('productId', this.getCurrentProduct.id)
        .orderBy('timestamp', 'desc')
        .all()
    },

    creditLineDeadlines() {
      return CreditLineDeadline.paidForCurrentOrganization()
    }
  },

  watch: {
    activeTab(this: any, tab) {
      if (tab === 'beneficiary') {
        this.fetchDispatchings()
      } else {
        this.fetchCreditLineDeadlines()
      }
    }
  },

  created(this: any) {
    if (this.$granted('admin|ceo|agencyhead')) {
      this.activeTab = 'beneficiary'
    } else {
      this.activeTab = 'fnm'
    }

    if (this.activeTab === 'beneficiary') {
      this.fetchDispatchings()
    } else {
      this.fetchCreditLineDeadlines()
    }
  },

  methods: {
    onSeeDetails(dispatching: Dispatching) {
      this.$router.push({
        name: 'finances.refunds.group',
        params: { dispatchingId: dispatching.id }
      })
    },

    fetchCreditLineDeadlines(this: any) {
      this.loading = 'fnm'
      CreditLineDeadline.api()
        .forCurrentOrganization()
        .then(() => {
          this.loading = 'none'
        })
        .catch((error: Error) => {
          console.error(error)
          this.loading = 'none'
        })
    },

    fetchDispatchings(this: any) {
      this.loading = 'beneficiary'
      Dispatching.api()
        .fetch({
          state: 'completed',
          include: ['beneficiaryGroups', 'creditLine']
        })
        .then(() => {
          this.loading = 'none'
        })
        .catch((error: Error) => {
          console.error(error)
          this.loading = 'none'
        })
    }
  }
})
